@use 'vars';

.notfound {
    max-width: 1080px;
    padding: 30px 20px;
    margin: 0 auto;
    // background: vars.$bg-200;

    >.notfound__nav {
        display: grid;
        grid-template-rows: max-content;
        grid-template-columns: max-content 1fr;
        grid-template-areas:
            "logo search__box";

        @include vars.mq(sp) {
            grid-template-rows: max-content max-content;
            grid-template-columns: 1fr;
            grid-template-areas:
                "logo"
                "search__box";
        }

        >.nav__logo {
            grid-area: logo;
            height: 40px;
            padding: 5px;

            @include vars.mq(sp) {
                width: max-content;
                margin: 20px auto;
            }
        }

        >.nav__search {
            display: flex;
            align-items: center;
            height: 45px;
            padding: 10px 30px;
            margin-left: 30px;
            grid-area: search__box;
            background: white;
            border-radius: 50px;
            box-shadow: 0 2px 5px 1px vars.$bg-200;

            >.search__ico {
                @include vars.font(1rem, vars.$te-200);
                padding-right: 20px;
            }

            >.search__item {
                position: relative;
                width: max-content;
                @include vars.font(1rem, vars.$te-100);

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -5px;
                    height: 100%;
                    width: 1px;
                    background: vars.$te-200;
                    animation: blinkCursor .5s ease infinite alternate;
                }

                @keyframes blinkCursor {
                    0% {
                        opacity: 0;
                    }

                    30% {
                        opacity: 0;
                    }

                    70% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 1;
                    }
                }
            }
        }
    }

    >.searchs__txts {
        padding: 10px 30px;
        @include vars.font(.8rem, vars.$te-200);
    }

    >.maybe__txts {
        padding: 20px;

        >span {
            @include vars.font(1rem, red);
        }

        >a {
            @include vars.font(1.1rem, #00e);
            margin-left: 5px;
            font-weight: bold;
        }
    }

    >.notfound__desc {
        >.descs {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            >.desc__ttl {
                @include vars.font(3rem, vars.$te-100);
                margin-bottom: 30px;
            }

            >.desc__txts {
                padding: 50px;
                text-align: center;

                >p {
                    @include vars.font(1.2rem, vars.$te-100);
                    padding: 10px;
                }
            }
        }
    }
}