@use 'vars';

.postlist {
    >.postlist__topview {
        width: 100%;

        >.topview__ttl {
            max-width: 1080px;
            padding: 10px 20px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            >.ttl__main {
                @include vars.font(3rem, black);
                padding: 1rem;
                font-family: vars.$for-logo;

                @include vars.mq(tab) {
                    font-size: 2rem
                }
            }

            >.ttl__sub {
                @include vars.font(1.2rem, vars.$te-100);
                max-width: 80vw;
                padding-bottom: 2rem;
                line-height: 2;

                @include vars.mq(tab) {
                    font-size: 1rem;
                }
            }
        }
    }

    >.postCards {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 50px;

        >.postCard {
            height: max-content;
            width: 60%;
            padding: 20px 50px;
            background: white;
            border-radius: 20px;
            box-shadow: 2px 2px 8px vars.$primary-100;

            @include vars.mq(tab) {
                width: 90%;
            }

            >.card__desc {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // row-gap: 15px;

                .post__ttl a {
                    @include vars.font(2rem, vars.$te-100);

                    @include vars.mq(tab) {
                        font-size: 1.5rem;
                    }
                }

                .post__cat {
                    margin-top: 10px;
                }
            }

            >.post__more {
                @include vars.font(1rem, vars.$primary-100);
                font-weight: bold;
                display: block;
                text-align: center;
                padding-top: 20px;
            }
        }
    }

    .to__courses {
        margin: 50px auto;
    }
}