@use 'vars';


.share {
    max-width: 500px;
    width: 100%;
    padding: 10px;
    margin: 30px auto;
    // border: 1px solid vars.$bg-300;
    background: vars.$bg-100;
    border-radius: 20px;

    &__ttl {
        @include vars.font(2rem, vars.$te-100);
        font-family: vars.$for-logo;
        text-align: center;
        padding-bottom: 20px;
    }

    &__links {
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &__btn {
            cursor: pointer;
            -webkit-tap-highlight-color: #0000;

            svg {
                display: block;
                height: 2rem;
            }
        }
    }
}