@use 'vars';


.post__date {
    width: max-content;
    padding: 10px 0;
    @include vars.font(1.2rem, vars.$te-100);

    &>.date__ico {
        margin-right: 10px;
    }
}

.post__ttl {
    padding: 10px 0;
    @include vars.font(2rem, vars.$te-100);
    font-weight: bold;
}

.post__cat {
    width: max-content;
    padding: 2px 5px;
    color: vars.$te-100;
    border: solid 2px vars.$bg-200;
    border-radius: 5px;
    background: white;

    &>.cat__ico {
        margin-right: 10px;
    }
}

.post {
    display: flex;
    height: max-content;
    min-height: 100vh;

    .post__toc {
        position: sticky;
        top: 60px;
        height: 100vh;
        width: 320px;
        padding: 20px;
        background: white;
        border-right: solid 1px vars.$bg-200;

        @include vars.mq(tab) {
            position: fixed;
            top: 60px;
            width: 50%;
            visibility: hidden;
            opacity: 0;
            transform: translateX(-100%);
            transition: all .3s;
            z-index: 10;

            &.active {
                visibility: visible;
                opacity: 1;
                transform: translateX(0px);
            }
        }

        .toc {
            margin: 0 auto;
            list-style: none;

            li {
                line-height: 2;
                border-bottom: solid 1px black;
                margin-bottom: 10px;

                &>a {
                    color: vars.$te-100;
                }
            }

            ul {
                list-style: none;
                padding-left: 30px;
            }
        }
    }

    .toggle__toclists {
        cursor: pointer;
        display: none;
        position: fixed;
        right: 30px;
        bottom: 70px;
        height: 50px;
        width: 50px;
        background: white;
        border: solid 2px vars.$primary-100;
        border-radius: 50%;
        transition: all .3s;
        z-index: 20;

        >.toclists__ico {
            @include vars.font(20px, vars.$primary-100);
        }

        &.active {
            background: vars.$primary-100;

            >.toclists__ico {
                @include vars.font(20px, vars.$bg-100);
            }
        }

        @include vars.mq(tab) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .post__article {
        width: calc(100% - 320px);
        padding: 20px;

        @include vars.mq(tab) {
            width: 100%;
        }

        .post__back {
            width: max-content;
            padding: 5px 15px;
            margin-bottom: 20px;
            background: white;
            border: solid 2px vars.$primary-100;
            border-radius: 100px;
            transition: .3s;

            >a {
                text-decoration: none;
                @include vars.font(1rem, vars.$primary-100);
                transition: .3s;
            }

            &:hover {
                background: vars.$primary-100;

                >a {
                    color: white;
                }
            }
        }

        .post__author {
            width: max-content;
            display: flex;
            align-items: center;
            gap: 20px;
            margin: 20px 0;

            .author__ico {
                height: 40px;
                width: 40px;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
            }

            .author__name {
                @include vars.font(20px, vars.$te-100);
            }
        }

        .post__contents {

            .codes__wrapper {
                padding: 10px;
                margin: 30px 0;
                background: #272822;

                .codes__header {
                    height: max-content;
                    width: 100%;
                    padding-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #272822;

                    >.file__name {
                        @include vars.font(1rem, vars.$te-200);
                        font-weight: bold;
                    }

                    >.copy__codes {
                        padding: 2px 6px;
                        @include vars.font(1rem, vars.$primary-200);
                        background: transparent;
                        border: solid 1px vars.$primary-200;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }

            >h1,
            >h2,
            >h3 {
                color: vars.$te-100;
                padding: 75px 10px 10px 10px;
                margin: -40px 0 25px 0;
                border-bottom: solid 1px vars.$bg-200;

                &::before {
                    padding-right: 10px;
                    font-family: vars.$for-code;
                    background: vars.$liner-100;
                    background-clip: text;
                }
            }

            >h1 {
                font-size: 28px;

                &::before {
                    content: '#';
                    @include vars.font(32px, transparent);
                }
            }

            >h2 {
                font-size: 24px;

                &::before {
                    content: '##';
                    @include vars.font(28px, transparent);
                }
            }

            >h3 {
                font-size: 20px;
            }

            p {
                color: vars.$te-100;
                line-height: 2;

                >strong {
                    background: linear-gradient(to bottom, transparent 60%, vars.$primary-200 60%);
                }
            }

            ul {
                padding-left: 20px;
                margin: 20px 0;

                li {
                    line-height: 2;
                }
            }

            :not(pre)>code {
                @include vars.font(.9rem, vars.$primary-100);
                background: vars.$bg-200;
                padding: 1px 4px;
                margin: 0 2px;
                border-radius: 2px;
            }

            iframe {
                height: 400px;
                margin: 30px 0;
            }

            >blockquote {
                border-left: 4px solid vars.$bg-200;
                padding: 15px 0 15px 20px;
                margin: 1.5em 0;
            }
        }
    }
}