@use 'vars';

.home {
    >section {

        .backwave {
            width: 100%;
            transform: translateY(1px);
            z-index: 2;
        }

        .rev__wave {
            transform: rotate(180deg) translateY(1px);
        }

        .section__ttl {
            display: flex;
            flex-direction: column;

            >.ttl__main {
                width: max-content;
                line-height: 1;
                @include vars.font(calc(2rem + 3vw), vars.$nocl);
                word-break: keep-all;
                font-family: vars.$for-logo;
                background: vars.$liner-100;
                background-clip: text;
            }

            >.ttl__sub {
                padding-top: 20px;
                @include vars.font(calc(1rem + 1vw), vars.$te-200);
            }
        }
    }

    >.home__topview {
        height: max-content;
        width: 100%;
        // background: url('../conponents/media/topview.jpg') center;
        // background-size: cover;

        @include vars.mq(sp) {
            background-position: 20% center;
        }

        >.topview__inner {
            position: relative;
            width: 100%;
            padding: 0 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0px;

            @include vars.mq(tab) {
                margin: 50px 0;
                gap: 30px;
            }

            >.inner__desc {
                display: flex;
                align-items: center;

                @include vars.mq(tab) {
                    flex-direction: column-reverse;
                }

                >.desc__ttls {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 30px;

                    .ttl__main {
                        word-break: keep-all;
                        text-align: start;
                        @include vars.font(calc(2rem + 2vw), vars.$te-100);
                        max-width: 17ch;
                    }

                    .ttl__sub {
                        @include vars.font(calc(1rem + 1vw), vars.$te-200);
                    }
                }

                >.desc__pic {
                    width: 500px;

                    @include vars.mq(tab) {
                        width: 70%;
                    }
                }
            }

            >.inner__buttons {
                display: flex;
                align-items: center;
                gap: 70px;

                @include vars.mq(sp) {
                    flex-direction: column;
                    gap: 30px;
                }
            }

            >.inner__scroll {
                position: absolute;
                bottom: -120px;
                height: 100px;
                width: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                z-index: 1;

                >.scroll__txt {
                    @include vars.font(1.2rem, vars.$te-200);
                    font-family: vars.$for-code;
                    font-weight: bold;
                }

                >.scroll__arrow {
                    rotate: 90deg;
                    scale: 1;
                    animation: upDown none 1s infinite ease alternate;

                    >path {
                        fill: vars.$te-200;
                    }
                }

                @keyframes upDown {
                    0% {
                        transform: translateX(-10px);
                    }

                    100% {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }

    >.home__about {
        width: 100%;
        padding: 60px 0;
        background: vars.$bg-300;


        >.about__inner {
            max-width: 1080px;
            margin: 0 auto;
            padding: 0 30px;
            display: grid;
            grid-template-rows: max-content max-content max-content;
            grid-template-columns: 50% 40%;
            grid-template-areas:
                "about__ttl     about__pic"
                "about__desc    about__pic"
                "about__btn     .";
            column-gap: 10%;

            @include vars.mq(tab) {
                grid-template-rows: max-content max-content max-content max-content;
                grid-template-columns: 100%;
                grid-template-areas: 
                    "about__ttl"
                    "about__pic"
                    "about__desc"
                    "about__btn";
            }

            >.about__ttl {
                grid-area: about__ttl;
                align-items: flex-start;

                >.ttl__sub {
                    padding-left: 10px;
                }
            }

            .about__desc {
                grid-area: about__desc;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                margin-top: 60px;

                >p {
                    @include vars.font(1.1rem, vars.$te-300);
                }
            }

            .about__pic {
                grid-area: about__pic;
                background: black;
                object-fit: cover;
                height: 300px;
                width: 100%;
                margin-top: 30px;
                border-radius: 50px;
            }

            .about__btn {
                grid-area: about__btn;
                margin-top: 50px;
            }
        }
    }

    .home__course {
        .course__inner {
            max-width: 1080px;
            margin: 0 auto;
            padding: 0 30px;

            .course__ttl {
                align-items: flex-end;
                margin-bottom: 30px;

                .ttl__sub {
                    padding-right: 10px;
                }
            }

            >.course__desc {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 30px;

                .map__wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    row-gap: 70px;
                    height: max-content;
                    width: max-content;

                    >.map__item {
                        position: relative;
                        display: flex;
                        align-items: center;
                        z-index: 2;

                        >.item__box {
                            display: flex;
                            align-items: center;
                            box-shadow: 0 0 8px vars.$bg-300;
                            border-radius: 20px;
                            overflow: hidden;

                            @include vars.mq(tab) {
                                flex-direction: column;
                            }

                            >.box__pic {
                                height: 250px;
                                width: 300px;
                                background: white;
                                object-fit: cover;

                                @include vars.mq(tab) {
                                    height: 250px;
                                    width: 80vw;
                                }
                            }

                            >.box__txts {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                row-gap: 20px;
                                height: 250px;
                                width: 400px;
                                padding: 0 20px;
                                background: vars.$primary-100;

                                @include vars.mq(tab) {
                                    height: max-content;
                                    width: 80vw;
                                    padding: 30px;
                                }

                                >p {
                                    @include vars.font(1.2rem, white);
                                    font-family: vars.$for-logo;
                                }
                            }
                        }

                        >.item__num {
                            position: absolute;
                            top: 0;
                            left: 0;
                            transform: translateY(-50%);
                            @include vars.font(5rem, vars.$primary-100);
                            font-family: vars.$for-code;
                        }
                    }
                }

                >.course__start {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 50px;
                    margin: 100px 0;

                    >.start__txts {
                        @include vars.font(2rem, vars.$te-100);
                        font-family: vars.$for-logo;
                    }
                }
            }
        }
    }

    >.home__contact {
        padding: 100px 0;
        background: vars.$bg-100;

        >.contact__inner {
            max-width: 1080px;
            margin: 0 auto;
            padding: 0 30px;

            >.contact__ttl {
                align-items: center;
            }

            >.contact__desc {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 50px;

                >.desc__txts {
                    max-width: 600px;
                    text-align: center;
                    @include vars.font(1.5rem, vars.$te-100);
                    font-family: vars.$for-logo;

                    @include vars.mq(sp) {
                        font-size: 1.2rem;
                    }
                }

                >.contacts__wrapper {
                    margin-top: 100px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 70px;

                    >.contacts__item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        row-gap: 50px;
                        max-width: 600px;
                        width: 80vw;
                        padding: 50px;
                        background: vars.$bg-200;
                        border-radius: 20px;

                        >.contacts__ttl {
                            @include vars.font(1.5rem, vars.$te-100);
                            font-family: vars.$for-logo;
                        }

                        >.contacts__links {
                            display: flex;
                            align-items: center;
                            column-gap: 50px;

                            @include vars.mq(tab) {
                                flex-direction: column;
                                align-items: flex-start;
                                row-gap: 20px;
                            }

                            >.contacts__link {
                                display: flex;
                                align-items: center;

                                >.link__ico {
                                    height: 30px;
                                }

                                >.link__txt {
                                    @include vars.font(1.2rem, vars.$primary-100);
                                    font-family: vars.$for-code;
                                    padding-left: 10px;
                                }
                            }
                        }
                    }
                }

                >.contacts__btn {
                    margin-top: 100px;
                }
            }
        }
    }
}