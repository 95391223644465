@use 'vars';

footer {
    position: sticky;
    bottom: -100px;
    background: vars.$liner-100;
    font-family: vars.$for-code;

    .footer__inner {
        height: 100px;
        max-width: 1080px;
        padding: 0 30px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        >.copyright__By {
            @include vars.font(.8rem, white);
            >.heart {
                color: red;
            }
        }

        >.copyright__txt {
            @include vars.font(.8rem, white);
        }

        .scroll__top {
            position: fixed;
            right: 30px;
            bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 50px;
            background: vars.$primary-100;
            border-radius: 50%;
            z-index: 10;

            >.scroll__ico {
                @include vars.font(20px, vars.$bg-100);
            }
        }
    }
}