@use 'header';
@use 'homepage';
@use 'about';
@use 'cource';
@use 'postlist';
@use 'post';
@use 'share';
@use 'notfound';
@use 'footer';
@use 'vars';

body.active {
    overflow: hidden;
}

.App {
    min-height: 100vh;
    background: white;
    font-family: vars.$nofont;
}

// settings
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    background: white;
    box-shadow: -5px -5px 5px black;
}

body::-webkit-scrollbar-thumb {
    background: vars.$primary-100;
    border: solid 4px white;
    border-radius: 50px;
}

a {
    text-decoration: none;
    -webkit-tap-highlight-color: #0000;
}

img {
    max-width: 100%;
    vertical-align: bottom;
}

.colorLinear {
    word-break: keep-all;
    font-family: vars.$for-logo;
    color: transparent;
    background: vars.$liner-100;
    background-clip: text;
}

.hidden__anime {
    opacity: 0;
    visibility: hidden;
    transform: translate(-20px, 20px);
    transition: .3s;
}

.active__anime {
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
}

.primary__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 50px;
    min-width: 200px;
    width: max-content;
    background: white;
    border: solid 2px vars.$primary-100;
    border-radius: 50px;
    transition: all .3s ease;

    >.inner__txt {
        @include vars.font(20px, vars.$primary-100);
        font-family: vars.$for-logo;
    }

    >.btn__arrow {
        height: 20px;
        margin-left: 20px;
        transform: translateX(-10px);
        transition: all .3s ease;

        >path {
            fill: vars.$primary-100;
        }
    }

    &:hover {
        background: vars.$primary-100;

        >.inner__txt {
            color: white;
        }

        >.btn__arrow {
            transform: translateX(0px);

            >path {
                fill: white;
            }
        }
    }
}