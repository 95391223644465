@use 'vars';

.header__support {
    padding-top: 60px;
}

.header__wrapper {
    position: sticky;
    top: 0;
    z-index: 999;
}

header {
    user-select: none;
    height: max-content;
    width: 100%;
    padding: 0 20px;
    background: vars.$blur;
    backdrop-filter: saturate(200%) blur(20px);
    font-family: vars.$for-logo !important;
    box-shadow: 0 2px 4px vars.$bg-200;
    z-index: 10;

    >.header__inner {
        height: 60px;
        max-width: 1080px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include vars.mq(sp) {
            justify-content: center;
        }

        >.header__ttl>a {
            display: flex;
            align-items: center;
            column-gap: 10px;
            @include vars.font(2rem, vars.$nocl);
        }

        .header__menu {
            display: flex;
            align-items: center;

            @include vars.mq(sp) {
                position: fixed;
                top: 0;
                left: 0;
                height: 100vh;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 50px;
                background: vars.$bg-100;
                visibility: hidden;
                opacity: 0;
                transition: .3s;
            }

            &.active {
                visibility: visible;
                opacity: 1;
                z-index: 10;
            }

            >.menu__ttl>a {
                display: none;

                @include vars.mq(sp) {
                    display: block;
                    @include vars.font(3.5rem, vars.$nocl);
                }
            }

            >ul {
                display: flex;
                align-items: center;
                gap: 50px;
                list-style: none;
                margin-right: 80px;

                @include vars.mq(tab) {
                    gap: 30px;
                    margin-right: 30px;
                }

                @include vars.mq(sp) {
                    flex-direction: column;
                    gap: 30px;
                    margin-right: 0;
                }

                >li>a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    >span {
                        @include vars.font(.8rem, vars.$te-200);
                        line-height: 1.2;

                        @include vars.mq(sp) {
                            @include vars.font(1.5rem, vars.$te-200);
                        }

                        &.is__bigger {
                            @include vars.font(1.45rem, vars.$te-100);
                            position: relative;
                            width: max-content;
                            padding: 2px;

                            &::after {
                                content: '';
                                position: absolute;
                                bottom: 0px;
                                left: 50%;
                                height: 2px;
                                width: 60px;
                                background: vars.$primary-200;
                                transform: translateX(-50%);
                                border-radius: 2px;
                            }

                            @include vars.mq(sp) {
                                @include vars.font(2rem, vars.$te-100);
                            }
                        }
                    }
                }
            }

            >.sns {
                display: flex;
                align-items: center;
                gap: 20px;

                @include vars.mq(sp) {
                    gap: 40px;
                }

                >a {
                    >svg {
                        font-size: 25px;
                        color: black;
                        vertical-align: bottom;

                        @include vars.mq(sp) {
                            font-size: 40px;
                        }
                    }

                    >img {
                        height: 25px;
                        width: 25px;

                        @include vars.mq(sp) {
                            height: 40px;
                            width: 40px;
                        }
                    }
                }
            }
        }
    }
}

.hum {

    @include vars.mq(sp) {
        cursor: pointer;
        position: fixed;
        left: 50vw;
        bottom: 10px;
        transform: translate(-50%, 0);
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background: vars.$primary-100;
        -webkit-tap-highlight-color: #0000;
        transition: all .3s;
        z-index: 20;

        >span {
            height: 2px;
            width: 25px;
            background: vars.$bg-100;
            border-radius: 2px;
            transition: .5s;
        }

        &.active {
            @include vars.mq(sp) {

                >span:nth-of-type(1) {
                    width: 25px;
                }

                >span:nth-of-type(2) {
                    width: 15px;
                }

                >span:nth-of-type(3) {
                    width: 5px;
                }
            }
        }
    }
}