@use 'vars';

.course {
    >.course__topview {
        width: 100%;

        >.topview__ttl {
            padding: 10px 20px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            >.ttl__main {
                @include vars.font(3rem, black);
                padding: 1rem;
                font-family: vars.$for-logo;

                @include vars.mq(tab) {
                    font-size: 2rem
                }
            }

            >.ttl__sub {
                @include vars.font(1.2rem, vars.$te-100);
                line-height: 2;

                @include vars.mq(tab) {
                    font-size: 1rem;
                }
            }
        }
    }

    >.course__cats {
        max-width: 1080px;
        padding: 50px 20px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 50px 5%;

        @include vars.mq(tab) {
            flex-wrap: nowrap;
            flex-direction: column;
            gap: 50px;
        }

        >.course__cat {
            height: 350px;
            min-width: 250px;
            width: 30%;
            padding: 50px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: white;
            border-radius: 20px;
            box-shadow: 2px 2px 8px vars.$primary-100;

            @include vars.mq(tab) {
                height: max-content;
                width: 90%;
            }

            >img {
                height: 100px;
                margin-bottom: 10px;
            }

            >.cat__ttl {
                @include vars.font(2rem, vars.$te-100);
                font-weight: bold;
                margin-bottom: 10px;
            }

            >.cat__desc {
                @include vars.font(1rem, vars.$te-100);
                line-height: 2;
            }
        }
    }
}