// colors
$primary-100: #2869ff;
$primary-200: #28ffbe;
$liner-100: linear-gradient(45deg, $primary-100, $primary-200 100%);
$te-100: #1E2022;
$te-200: #52616B;
$te-300: #B4A5A5;

$bg-100: #F0F5F9;
$bg-200: #C9D6DF;
$bg-300: #171717;

$blur: rgba(255, 255, 255, .5);
$nocl: transparent;

// fonts
$for-logo: 'Yuji Syuku';
$nofont: 'Noto Sans JP';
$for-code: 'Source Code Pro';

$breakpoint: (
    sp: 'screen and (max-width: 480px)',
    tab: 'screen and (max-width: 768px)',
    pc: 'screen and (max-width: 1080px)',
);

@mixin mq($bp) {
    @media #{map-get($breakpoint, $bp)} {
        @content
    }
}

;

@mixin font($size, $color) {
    font-size: $size;
    color: $color;
}

;